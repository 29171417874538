import actions from './actions';

const initState = {
  sales: null,
  editedSale: null,
  salesItems: null,
};

export default function saleReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_SALES_LIST_SUCCESS:
      return {
        ...state,
        sales: action.response.data,
      };
    case actions.SET_EDIT_SALE:
      return {
        ...state,
        editedSale: action.sale,
      };
    case actions.GET_SALES_LIST_BY_BRANCH_SUCCESS:
      return {
        ...state,
        sales: action.response.data,
      };
    case actions.CLEAR_SALE_STATE:
      return {
        ...state,
        salesItems: null,
        sales: null,
      };
    default:
      return state;
  }
}
