const actions = {
    SET_IMAGE: 'SET_IMAGE',
    SET_IMAGES: 'SET_IMAGES',
  setImage: (image) => ({
    type: actions.SET_IMAGE,
    image
  }),
  setImages: (images) => ({
    type: actions.SET_IMAGES,
    images
  }),
};
export default actions;
