import axiosInstance from '../../helpers/axios-instance';
import { order_endpoint, order_date_filter_endpoint, rider_get_url, get_order_endpoint, pdf_url,get_order_by_branch_endpoint, rider_create_url } from '../../URL';
const actions = {
  CREATE_ORDER: 'CREATE_ORDER',
  GET_SALES_LIST_BY_BRANCH_SUCCESS: 'GET_SALES_LIST_BY_BRANCH_SUCCESS',
  GET_ORDERS: 'GET_ORDERS',
  SET_EDIT_ORDER: 'SET_EDIT_ORDER',
  UPDATE_ORDER_SUCSESS: 'UPDATE_ORDER_SUCSESS',
  GET_PDF: 'GET_PDF',
  ASSIGNE_ORDER_TO_RIDER : "ASSIGNE_ORDER_TO_RIDER",
  LIST_ASSIGNE_ORDER_TO_RIDER:"LIST_ASSIGNE_ORDER_TO_RIDER",
  createOrder: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${order_endpoint}create`, payload)
        .then((response) =>
          dispatch({
            type: actions.CREATE_ORDER,
            response,
          }),
        );
    };
  },
  getOrdersByBranches: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${get_order_by_branch_endpoint}/${id}`).then((response) =>
        dispatch({
          type: actions.GET_ORDERS,
          response,
        }),
      );
    };
  },
  getOrders: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${get_order_endpoint}${id}`).then((response) =>
        dispatch({
          type: actions.GET_ORDERS,
          response,
        }),
      );
    };
  },
  getOrdersByDate: (filter) => {
    return (dispatch) => {
      return axiosInstance.post(`${order_date_filter_endpoint}`, filter).then((response) =>
        dispatch({
          type: actions.GET_ORDERS,
          response,
        }),
      );
    };
  },
  assignOrderToRider: (body) => {
    return (dispatch) => {
      return axiosInstance.post(`${rider_create_url}`, body).then((response) =>
        dispatch({
          type: actions.ASSIGNE_ORDER_TO_RIDER,
          response,
        }),
      );
    };
  },
  getAssignedOrderToRider: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${rider_get_url}/${id}`).then((response) =>
        dispatch({
          type: actions.LIST_ASSIGNE_ORDER_TO_RIDER,
          response,
        }),
      );
    };
  },
  deleteOrder: (id) => {
    return (dispatch) => {
      return axiosInstance
        .delete(`${order_endpoint}${id}`)
        .then((response) => {});
    };
  },
  updateOrder: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${order_endpoint}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_ORDER_SUCSESS,
            response,
          }),
        );
    };
  },
  getPDF: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${pdf_url}${id}`).then((response) => {
        dispatch({
          type: actions.GET_PDF,
          pdf: '',
        });
        return response.data;
      });
    };
  },
  setEditOrder: (order) => ({
    type: actions.SET_EDIT_ORDER,
    order,
  }),
};
export default actions;
