import actions from './actions';

const initState = {
  cuisines: null,
  cuisine: null,
  normalizedTree : []
};

export default function cuisineReducer(state = initState, action) {
  switch (action.type) {
    case actions.CUISINE_LIST_SUCCESS:
      return {
        ...state,
        cuisines: action.response.data,
      };
    case actions.CUISINE_TREE_SUCCESS:
        return {
          ...state,
          normalizedTree: action.deNormalizedData,
        };
    case actions.SET_EDIT_CATEGORY:
      return {
        ...state,
        node: action.node,
        viewOrEdit: action.bool
      };
    case actions.GET_NODETYPES:
      return {
        ...state,
        nodeTypes:
          action.response.data &&
          action.response.data.length > 0
            ? action.response.data
            : null,
      };
    default:
      return state;
  }
}
