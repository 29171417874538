import axiosInstance from '../../helpers/axios-instance';
import {
  user_url,
  single_user_url,
  assign_business_to_user,
  update_user,
  get_association_branches,
} from '../../URL';
const actions = {
  USERS_LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  USER_SUCCESS: 'USER_SUCCESS',
  ASSIGNMENT_USER_SUCCESS: 'ASSIGNMENT_USER_SUCCESS',
  GET_USER_ASSOCIATED_BUSINESS_LIST_SUCSESS:
    'GET_USER_ASSOCIATED_BUSINESS_LIST_SUCSESS',
  SET_EDIT_USER : 'SET_EDIT_USER',  
  UPDATE_USER_SUCSESS : 'UPDATE_USER_SUCSESS',

  getUsers: () => {
    return (dispatch) => {
      return axiosInstance.get(user_url).then((response) => {
        dispatch({
          type: actions.USERS_LIST_SUCCESS,
          response,
        });
        return response.data;
      });
    };
  },
  getUser: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${single_user_url}${id}`).then((response) => {
        dispatch({
          type: actions.USER_SUCCESS,
          response,
        });
        return response.data;
      });
    };
  },
  getUserAssociatedBusinesses: (id) => {
    return (dispatch) => {
      return axiosInstance
        .get(`${get_association_branches}${id}`)
        .then((response) =>
          dispatch({
            type: actions.GET_USER_ASSOCIATED_BUSINESS_LIST_SUCSESS,
            response,
          }),
        );
    };
  },
  assignBusinessToUser: (body) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${assign_business_to_user}`, body)
        .then((response) => {
          dispatch({
            type: actions.ASSIGNMENT_USER_SUCCESS,
            response,
          });
          return response.data;
        });
    };
  },
  editedUser: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${update_user}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_USER_SUCSESS,
            response,
          }),
        );
    };
  },
  setEditUser: (user) => ({
    type: actions.SET_EDIT_USER,
    user,
  }),
};
export default actions;
