import axiosInstance from '../../helpers/axios-instance';
import { role_endpoint, user_endpoint } from '../../URL';
const actions = {
  CREATE_ROLE: 'CREATE_ROLE',
  GET_USERS: 'GET_USERS',
  COMPOSE_ROLE: 'COMPOSE_ROLE',
  GET_ROLES : 'GET_ROLES',
  createRole: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${role_endpoint}create`, payload)
        .then((response) =>
          dispatch({
            type: actions.CREATE_ROLE,
            response,
          }),
      );
    };
  },
  getUsers: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${user_endpoint}`).then((response) =>
        dispatch({
          type: actions.GET_USERS,
          response,
        }),
      );
    };
  },
  getRoles : () => {
    return (dispatch) => {
      return axiosInstance.get(`${role_endpoint}`).then((response) =>
        dispatch({
          type: actions.GET_ROLES,
          response,
        }),
      );
    };
  },
  changeComposeRole: composeRole => ({
    type: actions.COMPOSE_ROLE,
    composeRole
  }),

};
export default actions;
