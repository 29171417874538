import React, { Component } from 'react';
import Input from '../uielements/input';
import { Icon } from 'antd';
import Switch from "../uielements/switch";

export default class extends Component {
  state = {
    value: this.props.value,
    editable: false,
    checked :false
  };
  handleChange = event => {
    this.setState({ checked : !this.state.checked });
    if(this.props.onCellChange){
        this.props.onCellChange(this.props.index, this.props.record, !this.state.checked)
    }
  };
  componentDidMount(){
      const {record} = this.props;
      console.log("SwitchCell", record)
      this.setState({checked : record.isActive })
      //this.setState({checked : record.item.isActive })
  }
  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(
        this.state.value,
        this.props.columnsKey,
        this.props.index
      );
    }
  };
  edit = () => {
    this.setState({ editable: true });
  };
  render() {
    const { value, editable, checked } = this.state;
    return (
      <div className="" >
        <Switch checked={checked} key={this.props.record.itemId} onChange={this.handleChange}/>
      </div>
    );
  }
}
