import actions from './actions';

const initState = {
  users: null,
  user: null,
  userBusinesses: null,
  editUser : null
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.USERS_LIST_SUCCESS:
      return {
        ...state,
        users: action.response.data,
      };
    case actions.GET_USER_ASSOCIATED_BUSINESS_LIST_SUCSESS:
      return {
        ...state,
        userBusinesses: action.response.data,
      };
    case actions.USER_SUCCESS:
      return {
        ...state,
        user:
          action.response.data && action.response.data.user
            ? action.response.data.user
            : null,
      };
    case actions.SET_EDIT_USER:
      return {
        ...state,
        editUser : action.user
      };
    default:
      return state;
  }
}
