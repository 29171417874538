import actions from './actions';

const initState = {
  item: null,
  itemArray: null,
  types: null,
};

export default function itemReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_EDIT_ITEM:
      return {
        ...state,
        item: action.item,
      };
    case actions.GET_ITEM_BY_ID:
      return {
        ...state,
        itemArray:
          action.response.data && action.response.data.length > 0
            ? action.response.data
            : null,
      };
    case actions.TYPE_LIST_SUCCESS:
      return {
        ...state,
        types:
          action.response.data &&
          action.response.data &&
          action.response.data.types.length > 0
            ? action.response.data.types
            : null,
      };
    default:
      return state;
  }
}
