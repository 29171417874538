import axiosInstance from '../../helpers/axios-instance';
import {
  create_cuisine,
  category_list,
  update_category,
  cuisine_url,
  get_item_by_id,
  fetch_all_types,
  generate_csv,
  uploadinventoryfile
} from '../../URL';
import { denormalize, normalize, schema } from 'normalizr';
import { getSchema } from '../../helpers/utility';
const actions = {
  CREATE_CUISINE: 'CREATE_CUISINE',
  CUISINE_LIST_SUCCESS: 'CUISINE_LIST_SUCCESS',
  SET_EDIT_CUISINE: 'SET_EDIT_CUISINE',
  SET_EDIT_CATEGORY: 'SET_EDIT_CATEGORY',
  SET_EDIT_ITEM: 'SET_EDIT_ITEM',
  UPDATE_CATEGORY_SUCSESS: 'UPDATE_CATEGORY_SUCSESS',
  MENU_CATEGORY_LIST_SUCCESS: 'MENU_CATEGORY_LIST_SUCCESS',
  CUISINE_TREE_SUCCESS: 'CUISINE_TREE_SUCCESS',
  GET_ITEM_BY_ID: 'GET_ITEM_BY_ID',
  TYPE_LIST_SUCCESS: 'TYPE_LIST_SUCCESS',
  createCuisine: (payload) => {
    return (dispatch) => {
      return axiosInstance.post(`${create_cuisine}`, payload).then((response) =>
        dispatch({
          type: actions.CREATE_CUISINE,
          response,
        }),
      );
    };
  },
  uploadBulkItems: (payload) => {
    return (dispatch) => {
      return axiosInstance.post(`${uploadinventoryfile}`, payload).then((response) =>{
        dispatch({
          type: actions.CREATE_CUISINE,
          response,
        })
        return response;
      });
    };
  },
  getCuisines: () => {
    return (dispatch) => {
      return axiosInstance.get(`${cuisine_url}`).then((response) => {
        dispatch({
          type: actions.CUISINE_LIST_SUCCESS,
          response,
        });
        return response.data;
      });
    };
  },
  getTypes: () => {
    return (dispatch) => {
      return axiosInstance.get(`${fetch_all_types}`).then((response) => {
        dispatch({
          type: actions.TYPE_LIST_SUCCESS,
          response,
        });
        return response.data;
      });
    };
  },
  getItemById: (id) => {
    console.log(`${get_item_by_id}?id=${id}`);
    return (dispatch) => {
      return axiosInstance
        .get(`${get_item_by_id}?id=${id}`)
        .then((response) => {
          dispatch({
            type: actions.GET_ITEM_BY_ID,
            response,
          });
          return response.data;
        });
    };
  },
  getCuisineTree: (id) => {
    return (dispatch) => {
      return axiosInstance
        .get(`${cuisine_url}searchalltree/`)
        .then((response) => {
          const normalizedData = normalize(response.data, getSchema());
          if (normalizedData.entities && normalizedData.entities.children) {
            for (let key in normalizedData.entities.children) {
              normalizedData.entities.children[key].label =
                normalizedData.entities.children[key].name;
              normalizedData.entities.children[key].value =
                normalizedData.entities.children[key]._id;
            }
          }
          const deNormalizedData = denormalize(
            normalizedData.result,
            getSchema(),
            normalizedData.entities,
          );
          dispatch({
            type: actions.CUISINE_TREE_SUCCESS,
            deNormalizedData,
          });
          return deNormalizedData;
        });
    };
  },
  updateMenuCategory: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${update_category}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_MENU_CATEGORY_SUCSESS,
            response,
          }),
        );
    };
  },
  deleteCuisine: (id) => {
    return (dispatch) => {
      return axiosInstance.delete(`${cuisine_url}${id}`).then((response) => {});
    };
  },
  generateCSV: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${generate_csv}${id}`).then((response) => {
        return response
      });
    };
  },
  setEditItem: (item) => ({
    type: actions.SET_EDIT_ITEM,
    item: item,
  }),
};
export default actions;
