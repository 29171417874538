import axiosInstance from '../../helpers/axios-instance';
import { create_menuCategory, category_list, update_category } from '../../URL';
const actions = {
  CREATE_MENU_CATEOGRY: 'CREATE_MENU_CATEOGRY',
  UPDATE_MENU_CATEGORY_SUCSESS: 'UPDATE_MENU_CATEGORY_SUCSESS',
  SET_EDIT_CATEGORY: 'SET_EDIT_CATEGORY',
  UPDATE_CATEGORY_SUCSESS: 'UPDATE_CATEGORY_SUCSESS',
  MENU_CATEGORY_LIST_SUCCESS: 'MENU_CATEGORY_LIST_SUCCESS',
  createMenuCategory: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_menuCategory}`, payload)
        .then((response) =>
          dispatch({
            type: actions.CREATE_MENU_CATEOGRY,
            response,
          }),
        );
    };
  },
  getCategories: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${category_list}${id}`).then((response) =>
        dispatch({
          type: actions.MENU_CATEGORY_LIST_SUCCESS,
          response,
        }),
      );
    };
  },
  updateMenuCategory: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${update_category}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_MENU_CATEGORY_SUCSESS,
            response,
          }),
        );
    };
  },
  deleteManuCategory: (id) => {
    return (dispatch) => {
      return axiosInstance
        .delete(`${update_category}${id}`)
        .then((response) => {});
    };
  },
  setEditCategory: (category) => ({
    type: actions.SET_EDIT_CATEGORY,
    category: category,
  }),
};
export default actions;
