import { BehaviorSubject } from 'rxjs';
import {
  login_url,
  signup_url,
  update_url,
  login_verifier,
  service_verifier,
  upload_file,
} from '../../URL';
import axios from 'axios';
import axiosInstance from '../../helpers/axios-instance';
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser')),
);
const signinError = new BehaviorSubject(null);
const signupError = new BehaviorSubject(null);
const currentToken = new BehaviorSubject(
  JSON.parse(localStorage.getItem('token')),
);

const authenticationService = {
  login,
  logout,
  signup,
  loginVerifier,
  serviceIdVerifier,
  updateUser,
  setUserInfo,
  uploadFile,
  token: currentToken.asObservable(),
  get currentTokenValue() {
    return currentToken.value;
  },
  currentUser: currentUserSubject.asObservable(),
  signupError: signupError.asObservable(),
  signinError: signinError.asObservable(),
  get signupErrorValue() {
    return this.signupError.value;
  },
  get currentUserValue() {
    return currentUserSubject.value;
  },
};
function login(userPayload) {
  return axios
    .post(login_url, userPayload, {
      headers: {
        domain: userPayload.domain === '' ? 'master' : userPayload.domain,
      },
    })
    .then(
      (user) => {
        user.domain = userPayload.domain;
        setUserInfo(user);
      },
      (e) => {
        signinError.next(e);
      },
    );
}
function updateUser(id, user) {
  return axiosInstance.put(`${update_url}${id}`, user).then((user) => {
    localStorage.setItem('currentUser', JSON.stringify(user.data));
    currentUserSubject.next(user.data);
    return user;
  });
}
function uploadFile(data) {
  return axiosInstance.post(upload_file, data).then((res) => {
    return res.data;
  });
}
function setUserInfo(user) {
  localStorage.setItem('currentUser', JSON.stringify(user.data.user));
  localStorage.setItem(
    'accessToken',
    JSON.stringify(user.data.token.accessToken),
  );
  localStorage.setItem(
    'refreshToken',
    JSON.stringify(user.data.token.refreshToken),
  );
  currentUserSubject.next(user.data.user);
  return user;
}
function loginVerifier(code, csrf) {
  return axios.post(login_verifier, {
    code: code,
    csrf: csrf,
  });
}
function serviceIdVerifier(body) {
  console.log("calling serviceIdVerifier from authService.js")
  return axios.post(service_verifier, body);
}
function signup(user) {
  return axios
    .post(signup_url, user, {
      headers: {
        domain: user.domain === '' ? 'master' : user.domain,
      },
    })
    .then(
      (user) => {
        // localStorage.setItem('currentUser', JSON.stringify(user.data.user));
        // localStorage.setItem(
        //   'accessToken',
        //   JSON.stringify(user.data.token.accessToken),
        // );
        // localStorage.setItem(
        //   'refreshToken',
        //   JSON.stringify(user.data.token.refreshToken),
        // );
        currentUserSubject.next(user.data);
        return user;
      },
      (e) => {
        signupError.next(e.response.data.errors);
      },
    );
}

function logout() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  currentUserSubject.next(null);
  currentToken.next(null);
}
export default authenticationService;
