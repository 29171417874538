import axiosInstance from '../../helpers/axios-instance';
import { create_sale } from '../../URL';
const actions = {
  CREATE_SALE: 'CREATE_SALE',
  GET_SALES_LIST_SUCCESS: 'GET_SALES_LIST_SUCCESS',
  SET_EDIT_SALE: 'SET_EDIT_SALE',
  UPDATE_SALE_SUCSESS: 'UPDATE_SALE_SUCSESS',
  GET_SALES_LIST_BY_BRANCH_SUCCESS: 'GET_SALES_LIST_BY_BRANCH_SUCCESS',
  CLEAR_SALE_STATE: 'CLEAR_SALE_STATE',
  createSale: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_sale}create`, payload)
        .then((response) =>
          dispatch({
            type: actions.CREATE_SALE,
            response,
          }),
        );
    };
  },
  getSales: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${create_sale}${id}`).then((response) =>
        dispatch({
          type: actions.GET_SALES_LIST_SUCCESS,
          response,
        }),
      );
    };
  },
  getItemsByBranch: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${create_sale}filter/${id}`).then((response) =>
        dispatch({
          type: actions.GET_SALES_LIST_BY_BRANCH_SUCCESS,
          response,
        }),
      );
    };
  },
  deleteSale: (id) => {
    return (dispatch) => {
      return axiosInstance.delete(`${create_sale}${id}`).then((response) => {});
    };
  },
  updateSale: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${create_sale}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_SALE_SUCSESS,
            response,
          }),
        );
    };
  },
  setEditSale: (sale) => ({
    type: actions.SET_EDIT_SALE,
    sale,
  }),
  clearSaleState: () => ({
    type: actions.CLEAR_SALE_STATE,
  }),
};
export default actions;
