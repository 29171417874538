import Auth from './auth/reducer';
import App from './app/reducer';
import Mails from './mail/reducer';
import Calendar from './calendar/reducer';
import Box from './box/reducer';
import Notes from './notes/reducer';
import Todos from './todos/reducer';
import Contacts from './contacts/reducer';
import Cards from './card/reducer';
import Chat from './chat/reducers';
import DynamicChartComponent from './dynamicEchart/reducer';
import Ecommerce from './ecommerce/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Invoices from './invoice/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import YoutubeSearch from './youtubeSearch/reducers';
import DevReducers from '../customApp/redux/reducers';
import Articles from './articles/reducers';
import Investors from './investors/reducers';
import scrumBoard from './scrumBoard/reducer';
import drawer from './drawer/reducer';
import modal from './modal/reducer';
import business from './business/reducer';
import menuCategory from './category/reducer';
import Sale from './sale/reducer';
import Order from './order/reducer';
import Role from './role/reducer';
import cuisineReducer from './cuisine/reducer';
import Item from './item/reducer';
import Images from './images/reducer';
import User from './user/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Images,
  Notes,
  business,
  Role,
  Todos,
  Cuisine: cuisineReducer,
  Contacts,
  Cards,
  Chat,
  Order,
  Item,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  Sale,
  scrumBoard,
  menuCategory,
  modal,
  drawer,
  User,
  ...DevReducers,
};
