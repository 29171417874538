import actions from './actions';

const initState = {
  orders: null,
  editedOrder: null,
  users: [],
  composeRole: false,
  replyMail: false,
};

export default function order(state = initState, action) {
  switch (action.type) {
    case actions.SET_EDIT_ORDER:
      return {
        ...state,
        editedOrder: action.order,
      };
    case actions.GET_ORDERS:
      return {
        ...state,
        orders: action.response.data,
      };
    case actions.GET_USERS:
      let users = {};
      action.response.data.forEach((user) => {
        users = {
          ...users,
          [user.id]: user,
        };
      });
      return {
        ...state,
        usersobject: { ...users },
        users: action.response.data
      };
    case actions.COMPOSE_ROLE:

      return {
        ...state,
        replyMail: false,
        composeRole: action.composeRole
      };
    case actions.GET_ROLES:
      return {
        ...state,
        roles: action.response.data
      }
    default:
      return state;
  }
}
