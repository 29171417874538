import actions from './actions';

const initState = {
  isBusinessCreated: false,
  businesses: null,
  businessId: null,
  editedBranch: null,
  items: null,
  page: 1,
  total: { length: 0 },
  pageSize: 10,
  hotItems: [],
  branches: null,
  selectedItems: [],
};
let hotItems = [];
export default function businessReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREATE_BUSINESS_SUCSESS:
      return {
        idToken: action.token,
      };
    case actions.GET_BUSINESS_LIST_SUCSESS:
      return {
        ...state,
        businesses: action.response.data,
      };
    case actions.GET_BRANCHES_LIST_SUCSESS:
      return {
        ...state,
        branches: action.response.data,
      };
    case actions.EDIT_BUSINESS:
      return {
        ...state,
        businessId: action.id,
      };
    case actions.GET_ASSOCIATED_BUSINESS_LIST_SUCSESS:
      return {
        ...state,
        businesses: action.response.data,
      };
    case actions.SET_EDIT_BRANCH:
      return {
        ...state,
        editedBranch: action.branch,
      };
    case actions.MENU_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        items: action.response.data,
        pageSize: 10,
      };
    case actions.SEARCH_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        items: action.response.data.items,
        total: { length: action.response.data.count },
        pageSize: action.response.data.count,
      };
    case actions.DETAIL_HOT_SEARCH_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        hotItems: action.response.data,
        selectedItems: action.response.data,
      };
    case actions.SET_SELECTED_ITEMS:
      hotItems = state.hotItems;
      let yFilter = action.items.map((itemY) => {
        return itemY._id;
      });
      let newSelectedItems = hotItems.filter((itemX) =>
        yFilter.includes(itemX._id),
      );
      return {
        ...state,
        selectedItems: newSelectedItems,
      };
    case actions.HOT_SEARCH_ITEMS_LIST_SUCCESS:
      let { selectedItems } = state;
      hotItems = selectedItems.concat(action.response.data);
      let newHotItems = hotItems.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t._id === item._id),
      );
      return {
        ...state,
        hotItems: newHotItems,
      };
    case actions.ITEMS_TOTAL_LENGTH:
      return {
        ...state,
        total: action.response,
      };
    case actions.CLEAR_DATA:
      return {
        ...state,
        businesses: null,
        items: null,
      };
    case actions.SET_PAGE_NUMBER:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
}
