import actions from './actions';

const initState = {
  orders: null,
  editedOrder: null,
  riders: null
};

export default function order(state = initState, action) {
  switch (action.type) {
    case actions.SET_EDIT_ORDER:
      return {
        ...state,
        editedOrder: action.order,
      };
    case actions.GET_ORDERS:
      return {
        ...state,
        orders: action.response.data,
      };
    case actions.LIST_ASSIGNE_ORDER_TO_RIDER:
      return {
        ...state,
        riders: action.response.data,
      };
    case actions.GET_PDF:
      return {
        ...state,
        pdf: action.pdf,
      };
    default:
      return state;
  }
}
