import actions from './actions';

const initState = {
    images : {}
};

export default function imageReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_IMAGE:
        const {image} = action;
        console.log(action)
        const {images}  = state;
        images[image.key] = image.value;
      return {
        ...state,
        images : {...images}
      };
     case actions.SET_IMAGES:
      return {
        ...state,
        images : {...action.images}
      };
    default:
      return state;
  }
}
