import React, { Component } from 'react';
import Popconfirm from '../feedback/popconfirm';
import { Icon } from 'antd';
export default class extends Component {
  render() {
    const { index, onDeleteCell, onEditCell, onViewCell, record } = this.props;
    return (
      <React.Fragment>
        <Icon
          type='edit'
          className='isoEditIcon'
          onClick={() => onEditCell(index, record)}
          style={{ paddingRight: '10px' }}
        />
        {onViewCell && (
          <a
            onClick={() => onViewCell(index, record)}
            style={{ paddingRight: '10px' }}
            href='# '
          >
            Add
          </a>
        )}
        {onDeleteCell && (
          <Popconfirm
            title='Sure to delete?'
            okText='DELETE'
            cancelText='No'
            onConfirm={() => onDeleteCell(index, record)}
          >
            <a style={{ paddingRight: '10px' }} href='# '>
              Delete
            </a>
          </Popconfirm>
        )}
      </React.Fragment>
    );
  }
}
