import axiosInstance from '../../helpers/axios-instance';
import { create_cuisine, category_list, update_category, cuisine_url, get_nodeTypes } from '../../URL';
import { denormalize, normalize, schema } from 'normalizr';
import {getSchema} from '../../helpers/utility';
const actions = {
  CREATE_CUISINE: 'CREATE_CUISINE',
  EDIT_CUISINE: 'EDIT_CUISINE',
  CUISINE_LIST_SUCCESS: 'CUISINE_LIST_SUCCESS',
  SET_EDIT_CUISINE: 'SET_EDIT_CUISINE',
  SET_EDIT_CATEGORY: 'SET_EDIT_CATEGORY',
  UPDATE_CATEGORY_SUCSESS: 'UPDATE_CATEGORY_SUCSESS',
  MENU_CATEGORY_LIST_SUCCESS: 'MENU_CATEGORY_LIST_SUCCESS',
  CUISINE_TREE_SUCCESS : 'CUISINE_TREE_SUCCESS',
  GET_NODETYPES: 'GET_NODETYPES',
  createCuisine: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_cuisine}`, payload)
        .then((response) =>
          dispatch({
            type: actions.CREATE_CUISINE,
            response,
          }),
      );
    };
  },
  editCuisine: (payload, id) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${cuisine_url}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.EDIT_CUISINE,
            response,
          }),
      );
    };
  },
  getCategories: () => {
    return (dispatch) => {
      return axiosInstance.get(`${cuisine_url}all`).then((response) => {
        dispatch({
          type: actions.CUISINE_LIST_SUCCESS,
          response,
        });
        return response.data;
      }
      );
    };
  },
  getNodeTypes: () => {
    return (dispatch) => {
      return axiosInstance.get(`${get_nodeTypes}`).then((response) => {
        console.log("getNodeTypes action", response)
        dispatch({
          type: actions.GET_NODETYPES,
          response,
        });
        return response.data;
      });
    };
  },
  getCaregoryTree: () => {
    return (dispatch) => {
      return axiosInstance.get(`${cuisine_url}searchalltree/`).then((response) => {
        const normalizedData = normalize(response.data, getSchema());
          if(normalizedData.entities && normalizedData.entities.children){
            for(let key in normalizedData.entities.children){
              normalizedData.entities.children[key].label =  normalizedData.entities.children[key].name
              normalizedData.entities.children[key].value =  normalizedData.entities.children[key]._id
            }
          }
          const deNormalizedData = denormalize(
            normalizedData.result,
            getSchema(),
            normalizedData.entities,
          );
        dispatch({
          type: actions.CUISINE_TREE_SUCCESS,
          deNormalizedData,
        });
        return deNormalizedData;
      }
      );
    };
  },
  updateMenuCategory: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${update_category}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_MENU_CATEGORY_SUCSESS,
            response,
          }),
      );
    };
  },
  deleteCuisine: (id) => {
    return (dispatch) => {
      return axiosInstance
        .delete(`${cuisine_url}${id}`)
        .then((response) => response);
    };
  },
  setEditCategory: (node, bool) => ({
    type: actions.SET_EDIT_CATEGORY,
    node: node,
    bool
  }),
};
export default actions;
