// const BASE_URL = 'http://18.222.132.102:3002';

//const BASE_URL = 'http://stagemarttime-env.khpytftitw.eu-central-1.elasticbeanstalk.com';
//const BASE_URL = 'http://localhost:3002';

const BASE_URL = process.env.REACT_APP_API_BASEURL;
//const BASE_URL = 'http://localhost:3002';

export const login_url = `${BASE_URL}/v1/auth/login`;
export const signup_url = `${BASE_URL}/v1/auth/register`;
export const create_create = `${BASE_URL}/v1/users/newuser`;
export const login_verifier = `${BASE_URL}/v1/auth/userLogin`;
export const service_verifier = `${BASE_URL}/v1/auth/user-exist`;
export const update_url = `${BASE_URL}/v1/users/`;
export const upload_file = `${BASE_URL}/v1/users/upload`;
export const upload = `${BASE_URL}/v1/fileupload/upload-file`;
export const create_business = `${BASE_URL}/v1/business/create`;
export const business_list = `${BASE_URL}/v1/business`;
export const update_business = `${BASE_URL}/v1/business/`;
export const delete_business = `${BASE_URL}/v1/business/`;
export const create_branches = `${BASE_URL}/v1/branch/create`;
export const get_association_branches = `${BASE_URL}/v1/association/`;
/**
* get_association_branches_with_custom_fields is used to get the list of 
* businesses and branches associated with logged in user
**/
export const get_association_branches_with_custom_fields = `${BASE_URL}/v1/association/`;
export const branches_list = `${BASE_URL}/v1/branch/`;
export const get_all_branches = `${BASE_URL}/v1/branch/list/getAllBranches`;
export const delete_branch = `${BASE_URL}/v1/branch/`;
export const update_branches = `${BASE_URL}/v1/branch/`;
export const create_Item = `${BASE_URL}/v1/item/`;
export const create_menuCategory = `${BASE_URL}/v1/menuCategory/create`;
export const get_search_items = `${BASE_URL}/v1/item/searchitemregex/`;

/**
  * update_item_collection is used to update the Item Collection
**/
export const update_item_collection = `${BASE_URL}/v1/item/updateItemCollection`;


/**
  * get_search_items_filters is used to search the items from Item Table and 
  * display on Admin Panel
**/
export const get_search_items_filters = `${BASE_URL}/v1/item/searchItemRegexWithFilters/`;
export const get_search_items_simple = `${BASE_URL}/v1/item/searchitemregexsimplefromasso/`;
export const category_list = `${BASE_URL}/v1/menuCategory/`;
export const update_category = `${BASE_URL}/v1/menuCategory/`;
export const create_sale = `${BASE_URL}/v1/sale/`;
export const order_endpoint = `${BASE_URL}/v1/order/`;
export const order_date_filter_endpoint = `${BASE_URL}/v1/order/listByDate`;
export const get_order_endpoint = `${BASE_URL}/v1/order/`;
export const save_order_history_endpoint = `${BASE_URL}/v1/order/saveorderhistory`;
export const get_order_by_branch_endpoint = `${BASE_URL}/v1/order/getOrderByUserBranches`;
export const role_endpoint = `${BASE_URL}/v1/role/`;
export const user_endpoint = `${BASE_URL}/v1/users/`;
export const create_cuisine = `${BASE_URL}/v1/cuisine/create`;
export const uploadinventoryfile = `${BASE_URL}/v1/item/uploadinventoryfile`;
export const cuisine_url = `${BASE_URL}/v1/cuisine/`;
export const pdf_url = `${BASE_URL}/v1/pdf/`;
export const user_url = `${BASE_URL}/v1/users/`;
export const single_user_url = `${BASE_URL}/v1/users/userbyuserid/`;
export const assign_business_to_user = `${BASE_URL}/v1/users/assignbusiness/`;
export const get_item_by_id = `${BASE_URL}/v1/item/getItemByIdFrmAsso/fetch`;
export const fetch_all_types = `${BASE_URL}/v1/type/all`;
export const generate_csv = `${BASE_URL}/v1/item/generatecsv/`;
export const rider_create_url = `${BASE_URL}/v1/rider/create`;
export const rider_get_url = `${BASE_URL}/v1/rider/list`;
export const update_user = `${BASE_URL}/v1/users/`;
export const get_subscriptions = `${BASE_URL}/v1/subscription/getSubscriptions`;
export const get_getSubscriptionById = `${BASE_URL}/v1/subscription/getSubscriptionById/`;
export const send_notification = `${BASE_URL}/v1/notifications/push/sendNotification`;
export const send_multipleNotifications = `${BASE_URL}/v1/notifications/push/sendMultipleNotifications`;
export const get_nodeTypes = `${BASE_URL}/v1/nodeType/`;

/**
* This API is used to fetch all the branches associated 
* with a particular item of Item Collection
**/
export const get_item_by_id_association = `${BASE_URL}/v1/item/getBranchWiseItemFromAssociationTable/`;


