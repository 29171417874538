import axiosInstance from '../../helpers/axios-instance';
import axios from 'axios';
import {
  create_business,
  business_list,
  update_business,
  delete_business,
  create_branches,
  get_association_branches,
  branches_list,
  delete_branch,
  update_branches,
  create_Item,
  reate_menuCategory,
  get_all_branches,
  get_search_items,
  get_search_items_filters,
  get_search_items_simple,
  get_association_branches_with_custom_fields,
  save_order_history_endpoint,
  update_item_collection,
} from '../../URL';
const actions = {
  CREATE: 'CREATE_BUSINESS',
  GET_BUSINESS_LIST_SUCSESS: 'GET_BUSINESS_LIST_SUCSESS',
  EDIT_BUSINESS: 'EDIT_BUSINESS',
  UPDATE_BUSINESS_SUCSESS: 'UPDATE_BUSINESS_SUCSESS',
  GET_ASSOCIATED_BUSINESS_LIST_SUCSESS: 'GET_ASSOCIATED_BUSINESS_LIST_SUCSESS',
  SET_EDIT_BRANCH: 'SET_EDIT_BRANCH',
  UPDATE_BRANCHES_SUCSESS: 'UPDATE_BRANCHES_SUCSESS',
  MENU_ITEMS_LIST_SUCCESS: 'MENU_ITEMS_LIST_SUCCESS',
  CLEAR_DATA: 'CLEAR_DATA',
  EDIT_ITEM: 'EDIT_ITEM',
  ITEMS_TOTAL_LENGTH: 'ITEMS_TOTAL_LENGTH',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SEARCH_ITEMS_LIST_SUCCESS: 'SEARCH_ITEMS_LIST_SUCCESS',
  HOT_SEARCH_ITEMS_LIST_SUCCESS: 'HOT_SEARCH_ITEMS_LIST_SUCCESS',
  DETAIL_HOT_SEARCH_ITEMS_LIST_SUCCESS: 'DETAIL_HOT_SEARCH_ITEMS_LIST_SUCCESS',
  SET_SELECTED_ITEMS: 'SET_SELECTED_ITEMS',
  UPDATE_ITEM_COLLECTION: 'UPDATE_ITEM_COLLECTION',
  GET_BRANCHES_LIST_SUCSESS: 'GET_BRANCHES_LIST_SUCSESS',
  deleteBusiness: (id) => {
    return (dispatch) => {
      return axiosInstance
        .delete(`${delete_business}${id}`)
        .then((response) => {
          return response;
        });
    };
  },
  deleteBranch: (id) => {
    return (dispatch) => {
      return axiosInstance.delete(`${delete_branch}${id}`).then((response) => {
        return response;
      });
    };
  },
  updateBranch: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${update_branches}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_BRANCHES_SUCSESS,
            response,
          }),
        );
    };
  },
  updateBusiness: (id, payload) => {
    return (dispatch) => {
      return axiosInstance
        .put(`${update_business}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_BUSINESS_SUCSESS,
            response,
          }),
        );
    };
  },
  createBranches: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_branches}`, payload)
        .then((response) =>
          dispatch({
            type: 'CREATE_BRANCHES_SUCCESS',
            response,
          }),
        );
    };
  },

  creatBusiness: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_business}`, payload)
        .then((response) =>
          dispatch({
            type: 'CREATE_BUSINESS_SUCSESS',
            response,
          }),
        );
    };
  },
  saveOrderHistory: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${save_order_history_endpoint}`, payload)
        .then((response) =>
          dispatch({
            type: 'CREATE_ORDER_HISTORY_SUCSESS',
            response,
          }),
        );
    };
  },
  getBusinesses: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${business_list}/${id}`).then((response) =>
        dispatch({
          type: 'GET_BUSINESS_LIST_SUCSESS',
          response,
        }),
      );
    };
  },
  getBranches: (id) => {
    return (dispatch) => {
      return axiosInstance.get(`${branches_list}${id}`).then((response) =>
        dispatch({
          type: 'GET_BRANCHES_LIST_SUCSESS',
          response,
        }),
      );
    };
  },
  getAllBranches: () => {
    return (dispatch) => {
      return axiosInstance.get(`${get_all_branches}`).then((response) =>
        dispatch({
          type: actions.GET_BRANCHES_LIST_SUCSESS,
          response,
        }),
      );
    };
  },
  getAssociatedBusinesses: (id) => {
    return (dispatch) => {
      return axiosInstance
        .get(`${get_association_branches}${id}`)
        .then((response) =>
          dispatch({
            type: actions.GET_ASSOCIATED_BUSINESS_LIST_SUCSESS,
            response,
          }),
        );
    };
  },
  getAssociatedBusinessesWithCustomFields: (id) => {
    return (dispatch) => {
      return axiosInstance
        .get(
          `${get_association_branches_with_custom_fields}withcustomfields/${id}`,
        )
        .then((response) => {
          console.log("actions.GET_ASSOCIATED_BUSINESS_LIST_SUCSESS", response)
          dispatch({
            type: actions.GET_ASSOCIATED_BUSINESS_LIST_SUCSESS,
            response,
          })
        })
    };
  },
  setEditBranch: (branch) => ({
    type: actions.SET_EDIT_BRANCH,
    branch: branch,
  }),
  setBusinessId: (businessId) => ({
    type: actions.EDIT_BUSINESS,
    id: businessId,
  }),
  createItem: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_Item}create`, payload)
        .then((response) =>
          dispatch({
            type: 'CREATE_ITEM_SUCCESS',
            response,
          }),
        );
    };
  },
  deleteItem: (id) => {
    console.log(`${create_Item}${id}`);
    return (dispatch) => {
      return axiosInstance.delete(`${create_Item}${id}`).then((response) => {
        return response;
      });
    };
  },

  updateItemCollection: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${update_item_collection}`, payload)
        .then((response) =>
          dispatch({
            type: actions.UPDATE_ITEM_COLLECTION,
            response,
          }),
        );
    };
  },

  editItemApi: (payload, id) => {
    console.log("editItemApi", payload)
    return (dispatch) => {
      return axiosInstance
        .put(`${create_Item}${id}`, payload)
        .then((response) =>
          dispatch({
            type: actions.EDIT_ITEM,
            response,
          }),
        );
    };
  },
  /** 
  * getItems is used to display the default list of items on items search page 
  * on Admin Panel. Only those items from a particular branches will be shown, where the logged in user is
  * assigned to branch(es)
  **/
  getItems: (id, page, perPage=10) => {
    return (dispatch) => {
      return axiosInstance
        .get(`${create_Item}${id}?page=${page}&perPage=${perPage}`)
        .then((response) =>
          dispatch({
            type: actions.MENU_ITEMS_LIST_SUCCESS,
            response,
          }),
        );
    };
  },
  getItemsDetail: (body) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_Item}itemsdetail`, body)
        .then((response) =>
          dispatch({
            type: actions.DETAIL_HOT_SEARCH_ITEMS_LIST_SUCCESS,
            response,
          }),
        );
    };
  },

  /**
  * searchItemsWithFilters is used to search the items from Item Table and 
  * display on Admin Panel
  **/
  searchItemsWithFilters: (body) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${get_search_items_filters}`, body)
        .then((response) => {
          console.log("searchItemsWithFilters - data length", response.data.length, response.data)
          dispatch({
            type: actions.SEARCH_ITEMS_LIST_SUCCESS,
            response,
          })
        })
    };
  },
  searchItems: (id, text) => {
    return (dispatch) => {
      return axiosInstance.get(`${get_search_items}${text}`).then((response) =>
        dispatch({
          type: actions.SEARCH_ITEMS_LIST_SUCCESS,
          response,
        }),
      );
    };
  },
  getItemsLength: (id) => {
    return (dispatch) => {
      return axiosInstance
        .get(`${create_Item}length/${id}?page=1`)
        .then((response) =>
          dispatch({
            type: actions.ITEMS_TOTAL_LENGTH,
            response: response.data,
          }),
        );
    };
  },
  hotSearch: makeRequestCreator(),
  setCurrentPageNUmber: (page) => ({
    type: actions.SET_PAGE_NUMBER,
    page,
  }),
  clearData: () => ({
    type: actions.CLEAR_DATA,
  }),

  setSelectedItems: (items) => ({
    type: actions.SET_SELECTED_ITEMS,
    items,
  }),
};
export default actions;

function makeRequestCreator() {
  let call;
  return (keyword, id) => (dispatch) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();

    return axiosInstance
      .get(`${get_search_items_simple}${keyword}?branch=${id}`, {
        cancelToken: call.token,
      })
      .then((response) => {
        dispatch({
          type: actions.HOT_SEARCH_ITEMS_LIST_SUCCESS,
          response,
        });
        return response.data;
      })
      .catch((thrown) => {});
  };
}
