import axiosInstance from '../../helpers/axios-instance';
import { create_create } from '../../URL';
const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SET_SERVICE_ID: 'SET_SERVICE_ID',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  createUser: (payload) => {
    return (dispatch) => {
      return axiosInstance
        .post(`${create_create}`, payload)
        .then((response) => {
          dispatch({
            type: 'CREATE_USER_SUCCESS',
            response,
          });
          return response;
        });
    };
  },
  setServiceId: (id) => ({ type: actions.SET_SERVICE_ID, id }),
};
export default actions;
