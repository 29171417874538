import actions from './actions';

const initState = {
  menuCategoryList: null,
  category: null,
};

export default function menuCategoryReducer(state = initState, action) {
  switch (action.type) {
    case actions.MENU_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        menuCategoryList: action.response.data,
      };
    case actions.SET_EDIT_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    default:
      return state;
  }
}
