import actions from './actions';

const initState = { idToken: null, serviceId: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
      };
    case actions.LOGOUT:
      return initState;
    case actions.SET_SERVICE_ID:
      return { ...state, serviceId: action.id };
    default:
      return state;
  }
}
